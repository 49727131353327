import { Create, useForm } from "@refinedev/antd";
import { Col, Form, Row } from "antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Amount, Diagnose, General, Positions } from "../../components/invoice";
import { INVOICE_CREATE_MUTATION } from "../../queries";

export const InvoiceCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    meta: {
      gqlMutation: INVOICE_CREATE_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.invoice.titles.create")}
      >
        <Form
          {...formProps}
          initialValues={{
            status: "OPEN",
            tax: "VAT_0",
            completedOn: undefined,
            amount: 0,
            positions: [],
            diagnoses: [],
          }}
        >
          <Row gutter={20}>
            <Col md={16}>
              <General editable />
              <Diagnose editable />
            </Col>
            <Col md={8}>
              <Amount editable />
            </Col>
          </Row>
          <Positions editable />
        </Form>
      </Create>
    </CanAccess>
  );
};
