import { Document, Font, Page, Text, View, Image } from "@react-pdf/renderer";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import dayjs from "dayjs";

type Props = {
  record?: any;
  translate: any;
};

export const Invoice = ({ record, translate }: Props) => {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "/fonts/Roboto-Regular.ttf",
      },
      {
        src: "/fonts/Roboto-Bold.ttf",
        fontWeight: 600,
      },
    ],
  });

  const Logo = () => (
    <View
      style={{
        width: 120,
        alignSelf: "center",
      }}
    >
      <Image src="/images/logo.png" />
    </View>
  );

  const SenderLine = () => (
    <Text
      style={{ marginTop: 20, fontSize: 7, color: "grey", marginBottom: 5 }}
    >
      {record?.settings?.companyName} - {record?.settings?.companyStreet} -{" "}
      {record?.settings?.companyPostcode} {record?.settings?.companyCity}
    </Text>
  );

  const ReceiverAddress = () => (
    <View style={{ lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.address?.name}</Text>
      <Text>{record?.address?.street}</Text>
      <Text>
        {record?.address?.postcode} {record?.address?.city}
      </Text>
      <Text>{record?.address?.country}</Text>
    </View>
  );

  const SenderAddress = () => (
    <View style={{ textAlign: "right", lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.settings?.companyName}</Text>
      <Text>{translate("pages.invoice.fields.job")}</Text>
      <Text>{record?.settings?.companyStreet}</Text>
      <Text>
        {record?.settings?.companyPostcode} {record?.companyCity}
      </Text>
    </View>
  );

  const InvoiceData = () => (
    <View
      style={{
        textAlign: "right",
        lineHeight: 1.3,
        fontSize: 10,
        paddingTop: 20,
      }}
    >
      <Text>
        {translate("pages.invoice.fields.date")}:{" "}
        {dayjs(record?.createdAt).format("L")}
      </Text>
      <Text>
        {translate("pages.invoice.fields.invoice_number")}: {record?.reference}
      </Text>
    </View>
  );

  const Title = () => (
    <Text style={{ paddingTop: 20, fontWeight: 600 }}>{translate("pages.invoice.titles.singular")}</Text>
  );

  const HeaderText = () => (
    <Text style={{ fontSize: 10, paddingTop: 10 }}>
      {record?.diagnoses?.map((x : any) => (x.code && String(x.code) + " ") + x.description).join("\r\n")}
    </Text>
  );

  const InvoiceTable = () => (
    <>
      <Table
        style={{ fontSize: 10, border: 0, paddingTop: 10 }}
        tdStyle={{ padding: 5 }}
      >
        <TH style={{ borderBottom: 1 }}>
          <TD weighting={0.1}>{translate("pages.invoice.fields.position")}</TD>
          <TD weighting={0.1}>{translate("pages.invoice.fields.number")}</TD>
          <TD weighting={0.4}>{translate("pages.invoice.fields.service")}</TD>
          <TD weighting={0.1} style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.quantity")}
          </TD>
          <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.sum_one")}
          </TD>
          <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.sum_all")}
          </TD>
        </TH>
        {record?.positions?.map((x: any, index: number) => (
          <TR key={index}>
            <TD weighting={0.1}>{index + 1}</TD>
            <TD weighting={0.1}>{x.service?.reference}</TD>
            <TD weighting={0.4}>{x.serviceName}</TD>
            <TD weighting={0.1} style={{ justifyContent: "flex-end" }}>
              {x.quantity}
            </TD>
            <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
              {Number(x.amountOne).toFixed(2) + " €"}
            </TD>
            <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
              {Number(x.amountSum).toFixed(2) + " €"}
            </TD>
          </TR>
        ))}
        <TR style={{ borderTop: 1 }}>
          <TD
            weighting={0.90}
            style={{ justifyContent: "flex-end", fontWeight: 600 }}
          >
            {translate("pages.invoice.fields.sum")}
          </TD>
          <TD style={{ justifyContent: "flex-end", fontWeight: 600 }}>
            {Number(record?.amount).toFixed(2) + " €"}
          </TD>
        </TR>
      </Table>
    </>
  );

  const FooterText = () => (
    <Text wrap style={{ fontSize: 10, paddingTop: 20 }}>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
      amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
    </Text>
  );

  const Footer = () => (
    <View
      style={{
        fontSize: 8,
        color: "grey",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 30,
        left: 50,
        right: 50,
      }}
      fixed
    >
      <Table style={{ border: 0 }} trStyle={{ height: 10 }}>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyName}</TD>
          <TD>
            {translate("pages.invoice.fields.bank")}:{" "}
            {record?.settings?.companyBank}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.tax")}:{" "}
            {record?.settings?.companyTax}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyStreet}</TD>
          <TD>
            {translate("pages.invoice.fields.iban")}:{" "}
            {record?.settings?.companyIBAN}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.register")}:{" "}
            {record?.settings?.companyRegister}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {record?.settings?.companyPostcode} {record?.settings?.companyCity}
          </TD>
          <TD>
            {translate("pages.invoice.fields.bic")}:{" "}
            {record?.settings?.companyBIC}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.court")}:{" "}
            {record?.settings?.companyCourt}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>Tel: {record?.settings?.companyTel}</TD>
          <TD />
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.owner")}:{" "}
            {record?.settings?.companyOwner}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>Fax: {record?.settings?.companyFax}</TD>
          <TD />
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pages.invoice.fields.url")}:{" "}
            {record?.settings?.companyUrl}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyEmail}</TD>
          <TD />
          <TD />
        </TR>
      </Table>
    </View>
  );

  const PageNumber = () => (
    <Text
      style={{ fontSize: 10, position: "absolute", bottom: 10, right: 10 }}
      render={({ pageNumber, totalPages }) =>
        `Seite ${pageNumber} von ${totalPages}`
      }
      fixed
    />
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 50,
          paddingTop: 20,
          paddingBottom: 100,
          fontFamily: "Roboto",
        }}
      >
        <Logo />
        <SenderLine />
        <ReceiverAddress />
        <SenderAddress />
        <InvoiceData />
        <Title />
        <HeaderText />
        <InvoiceTable />
        <FooterText />
        <Footer />
        <PageNumber />
      </Page>
    </Document>
  );
};
