import { useTranslate } from "@refinedev/core";
import { Divider, Tabs, TabsProps } from "antd";
import { Documents, Invoices, Receipts, Treatments } from ".";

type Props = {
  name? : string;
  documents?: any[];
  invoices?: any[];
  treatments?: any[];
  receipts?: any[];
};

export const Additional = ({
  name,
  documents,
  invoices,
  treatments,
  receipts,
}: Props) => {
  const translate = useTranslate();

  const items: TabsProps["items"] = [
     {
      key: "1",
      label: translate("titles.invoices"),
      children: <Invoices invoices={invoices} />,
    },
    {
      key: "2",
      label: translate("titles.treatments"),
      children: <Treatments treatments={treatments} />,
    },
    {
      key: "3",
      label: translate("titles.documents"),
      children: <Documents documents={documents} />,
    },
    {
      key: "4",
      label: translate("titles.receipts"),
      children: <Receipts receipts={receipts} name={name} />,
    },
  ];

  return (
    <>
      <Divider style={{ paddingTop: "20px" }}>
        {translate("pages.patient.fields.additional")}
      </Divider>
      <Tabs
        defaultActiveKey="1"
        items={items}
        centered
        indicator={{ size: (origin) => origin - 20, align: "center" }}
        size="large"
      />
    </>
  );
}
