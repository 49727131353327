import { SettingOutlined } from "@ant-design/icons";
import { Route } from "react-router-dom";
import { UserProps, UserRoute } from "./user";
import { GeneralProps, GeneralRoute } from "./general";
import { ServiceProps, ServiceRoute } from "./service";

export const SettingRoute = () => (
  <Route path="/setting">
    {GeneralRoute()}
    {ServiceRoute()}
    {UserRoute()}
  </Route>
);

export const SettingProps = [
  {
    name: "setting",
    meta: {
      icon: <SettingOutlined />,
    },
  },
  GeneralProps,
  ServiceProps,
  UserProps,
];
