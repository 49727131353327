import { TextField, useSelect } from "@refinedev/antd";
import { BaseRecord, useOne, useTranslate } from "@refinedev/core";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { SERVICE_SEARCH_QUERY, SERVICE_SELECT_QUERY } from "../../queries";

type Props = {
  onChange?: (service?: BaseRecord) => void;
  editable?: boolean;
  value?: BaseRecord;
  style?: React.CSSProperties;
};

export const SearchService = (props: Props) => {
  const translate = useTranslate();
  const { selectProps } = useSelect({
    resource: "services",
    debounce: 300,
    optionLabel: "name",
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
    meta: {
      gqlQuery: SERVICE_SEARCH_QUERY,
    },
    pagination: {
      pageSize: 20,
      mode: "server",
    },
  });

  const [id, setId] = useState(0);

  const onSelect = (option: any) => {
    setId(Number(option));
  };

  const { refetch } = useOne({
    resource: "services",
    id,
    meta: {
      gqlQuery: SERVICE_SELECT_QUERY,
    },
  });

  useEffect(() => {
    refetch().then((res) => {
      if (res.data?.data) {
        props.onChange && props.onChange(res.data.data);
      }
    });
  }, [id]);

  return props.editable ? (
    <Select
      {...selectProps}
      allowClear
      placeholder={translate("pages.invoice.placeholders.service")}
      onSelect={onSelect}
      onClear={() => props.onChange && props.onChange(undefined)}
      style={props.style}
      popupMatchSelectWidth={false}
      defaultValue={
        props.value && { label: props.value?.name, value: props.value }
      }
    />
  ) : (
    <TextField value={props.value?.name ?? "n.A."} />
  );
};
