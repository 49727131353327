import { Route } from "react-router-dom";
import { GeneralsList } from ".";
import { DatabaseOutlined } from "@ant-design/icons";

export const GeneralRoute = () => (
  <Route path="general" element={<GeneralsList />} />
);

export const GeneralProps = {
  name: "general",
  meta: {
    parent: "setting",
    icon: <DatabaseOutlined />,
  },
  list: "/setting/general",
};
