import { DateField, List, useTable } from "@refinedev/antd";
import {
  CanAccess,
  CrudFilters,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Dropdown, Table } from "antd";
import { PATIENTS_LIST_QUERY } from "../../queries";
import { useState } from "react";
import Search from "antd/es/input/Search";
import { Unauthorized, EmptyData } from "../../components/common";
import { DownOutlined } from "@ant-design/icons";

export const PatientsList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show, create, push } = useNavigation();
  const { tableProps } = useTable({
    resource: "patients",
    syncWithLocation: true,
    meta: {
      gqlQuery: PATIENTS_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        headerButtons={() => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.patient.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            <Dropdown.Button
              type="primary"
              onClick={() => push("create_wizard")}
              icon={<DownOutlined />}
              menu={{
                items: [
                  {
                    label: translate("buttons.create_without"),
                    onClick: () => create("patient"),
                    key: 1,
                  },
                ],
              }}
            >
              {translate("buttons.create")}
            </Dropdown.Button>
          </>
        )}
      >
        <Table
          {...tableProps}
          locale={{
            emptyText: <EmptyData text={translate("empty_data")} height={60} />,
          }}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => record.id && show("patient", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="id"
            title={translate("pages.patient.fields.id")}
            width="10%"
          />
          <Table.Column
            dataIndex="salutation"
            title={translate("pages.patient.fields.salutation")}
            width="10%"
          />
          <Table.Column
            dataIndex="name"
            title={translate("pages.patient.fields.name")}
          />
          <Table.Column
            dataIndex="birthdate"
            title={translate("pages.patient.fields.birthdate")}
            render={(value: any) =>
              value ? <DateField value={value} locales="de" /> : undefined
            }
            width="15%"
          />
          <Table.Column
            dataIndex="createdAt"
            title={translate("pages.patient.fields.createdAt")}
            render={(value: any) => <DateField value={value} locales="de" />}
            width="15%"
          />
          <Table.Column
            dataIndex="updatedAt"
            title={translate("pages.patient.fields.updatedAt")}
            render={(value: any) => <DateField value={value} locales="de" />}
            width="15%"
          />
        </Table>
      </List>
    </CanAccess>
  );
};
