import { Document, Font, Page, Text, View, Image } from "@react-pdf/renderer";
import { Table, TR, TD } from "@ag-media/react-pdf-table";
import dayjs from "dayjs";

type Props = {
  record?: any;
  translate?: any;
};

export const Receipt = ({ record, translate }: Props) => {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "/fonts/Roboto-Regular.ttf",
      },
      {
        src: "/fonts/Roboto-Bold.ttf",
        fontWeight: 600,
      },
    ],
  });

  const Logo = () => (
    <View
      style={{
        width: 120,
        alignSelf: "center",
      }}
    >
      <Image src="/images/logo.png" />
    </View>
  );

  const SenderLine = () => (
    <Text
      style={{ marginTop: 20, fontSize: 7, color: "grey", marginBottom: 5 }}
    >
      {record?.settings?.companyName} - {record?.settings?.companyStreet} -{" "}
      {record?.settings?.companyPostcode} {record?.settings?.companyCity}
    </Text>
  );

  const ReceiverAddress = () => (
    <View style={{ lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.name}</Text>
    </View>
  );

  const SenderAddress = () => (
    <View style={{ textAlign: "right", lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.settings?.companyName}</Text>
      <Text>{translate("pages.treatment.fields.job")}</Text>
      <Text>{record?.settings?.companyStreet}</Text>
      <Text>
        {record?.settings?.companyPostcode} {record?.companyCity}
      </Text>
    </View>
  );

  const ReceiptData = () => (
    <View
      style={{
        textAlign: "right",
        lineHeight: 1.3,
        fontSize: 10,
        paddingTop: 20,
      }}
    >
      <Text>
        {translate("pages.treatment.fields.date")}:{" "}
        {dayjs(record?.createdAt).format("L")}
      </Text>
      <Text>
        {translate("pages.treatment.fields.receipt_number")}:{" "}
        {record?.reference}
      </Text>
    </View>
  );

  const Ingestion = () => {
    if (record?.ingestion && record?.ingestion !== null) {
      return (
        <View style={{ paddingTop: 10 }}>
          <Text style={{ fontSize: 11, fontWeight: 600 }}>
            {translate("pages.treatment.titles.ingestion")}
          </Text>
          <Text style={{ fontSize: 10, paddingTop: 5 }}>
            {record?.ingestion}
          </Text>
        </View>
      );
    } else {
      return <div />;
    }
  };

  const Prescribed = () => {
    if (record?.prescribed && record?.prescribed !== null) {
      return (
        <View style={{ paddingTop: 10 }}>
          <Text style={{ fontSize: 11, fontWeight: 600 }}>
            {translate("pages.treatment.titles.prescribed")}
          </Text>
          <Text style={{ fontSize: 10, paddingTop: 5 }}>
            {record?.prescribed}
          </Text>
        </View>
      );
    } else {
      return <div />;
    }
  };

  const Title = () => (
    <Text style={{ paddingTop: 20, fontWeight: 600 }}>
      {translate("pages.treatment.titles.receipt")}
    </Text>
  );

  const Footer = () => (
    <View
      style={{
        fontSize: 8,
        color: "grey",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 30,
        left: 50,
        right: 50,
      }}
      fixed
    >
      <Table style={{ border: 0 }} trStyle={{ height: 10 }}>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyName}</TD>
        </TR>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyStreet}</TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {record?.settings?.companyPostcode} {record?.settings?.companyCity}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>Tel: {record?.settings?.companyTel}</TD>
        </TR>
        <TR>
          <TD weighting={0.4}>Fax: {record?.settings?.companyFax}</TD>
        </TR>
        <TR>
          <TD weighting={0.4}>{record?.settings?.companyEmail}</TD>
        </TR>
      </Table>
    </View>
  );

  const PageNumber = () => (
    <Text
      style={{ fontSize: 10, position: "absolute", bottom: 10, right: 10 }}
      render={({ pageNumber, totalPages }) =>
        `Seite ${pageNumber} von ${totalPages}`
      }
      fixed
    />
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 50,
          paddingTop: 20,
          paddingBottom: 100,
          fontFamily: "Roboto",
        }}
      >
        <Logo />
        <SenderLine />
        <ReceiverAddress />
        <SenderAddress />
        <ReceiptData />
        <Title />
        <Ingestion />
        <Prescribed />
        <Footer />
        <PageNumber />
      </Page>
    </Document>
  );
};
