import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import { CanAccess, useShow, useTranslate } from "@refinedev/core";
import { Button, Col, Row } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { BlobProvider } from "@react-pdf/renderer";
import { Unauthorized } from "../../components/common";
import { Invoice } from "../../components/pdf";
import { General, Amount, Diagnose, Positions } from "../../components/invoice";
import { INVOICE_DELETE_MUTATION, INVOICE_SHOW_QUERY } from "../../queries";

export const InvoiceShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: INVOICE_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.invoice.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {record?.settings && (
              <BlobProvider
                document={<Invoice translate={translate} record={record} />}
              >
                {({ url }) => (
                  <Button
                    icon={<PrinterOutlined />}
                    onClick={() => url && window.open(url, "_blank")?.focus()}
                  >
                    {translate("buttons.print")}
                  </Button>
                )}
              </BlobProvider>
            )}
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: INVOICE_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Row gutter={20}>
          <Col md={16}>
            <General record={record} />
            <Diagnose diagnoses={record?.diagnoses} />
          </Col>
          <Col md={8}>
            <Amount
              amount={record?.amount}
              tax={record?.tax}
              discountType={record?.discountType}
              discountValue={record?.discountValue}
            />
          </Col>
        </Row>
        <Positions positions={record?.positions} />
      </Show>
    </CanAccess>
  );
};
