import { Create, useForm } from "@refinedev/antd";
import { Col, Form, Row } from "antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { TREATMENT_CREATE_MUTATION } from "../../queries";
import {
  General,
  Diagnose,
  Treatment,
  Results,
  Files,
  Positions,
  Receipts,
} from "../../components/treatment";

export const TreatmentCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    meta: {
      gqlMutation: TREATMENT_CREATE_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.treatment.titles.create")}
      >
        <Form
          {...formProps}
          initialValues={{
            reference: "-",
            completedOn: undefined,
            positions: [],
            diagnoses: [],
            receipts: [],
            files: [],
          }}
        >
          <Row gutter={20}>
            <Col md={14}>
              <General editable />
              <Diagnose editable style={{ minHeight: "150px" }} />
              <Treatment editable />
              <Results editable />
            </Col>
            <Col md={10}>
              <Files editable />
              <Receipts editable />
            </Col>
          </Row>
          <Positions editable />
        </Form>
      </Create>
    </CanAccess>
  );
};
