import { DateField, TextField } from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  useInvalidate,
  useTranslate,
} from "@refinedev/core";
import { Button, Popconfirm, Table } from "antd";
import { InfoCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { gqlClient } from "../../provider";
import { FILE_DELETE_MUTATION, FILE_GET_LINK_QUERY } from "../../queries";

type Props = {
  documents?: any[];
};

export const Documents = ({ documents }: Props) => {
  const translate = useTranslate();
  const invalidate = useInvalidate();

  const [docs, setDocs] = useState(documents);

  const humanFileSize = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  const openDocument = async (id: BaseKey) => {
    let res = await gqlClient.request<{ document: string }>(
      FILE_GET_LINK_QUERY,
      {
        id,
      }
    );

    if (res && res.document) {
      window.open(res.document, "_blank")?.focus();
    }
  };

  const deleteDocument = async (id: BaseKey) => {
    let res = await gqlClient.request<boolean>(FILE_DELETE_MUTATION, {
      id,
    });

    if (res) {
      invalidate({
        resource: "invoices",
        invalidates: ["list", "many"],
      });

      setDocs((prev) => prev?.filter((x) => x.id !== id));
    }
  };

  return (
    <Table
      dataSource={docs}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: () => record.id && openDocument(record.id),
        };
      }}
      rowKey="id"
    >
      <Table.Column
        title={translate("pages.patient.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="10%"
      />
      <Table.Column
        dataIndex="name"
        title={translate("pages.patient.fields.name")}
      />
      <Table.Column
        dataIndex="size"
        title={translate("pages.patient.fields.size")}
        render={(value: any) => <TextField value={humanFileSize(value)} />}
        width="10%"
        align="right"
      />
      <Table.Column
        title={translate("table.actions")}
        dataIndex="actions"
        width={15}
        render={(_, record: BaseRecord) => (
          <Popconfirm
            title={translate("buttons.confirm")}
            okText={translate("buttons.delete")}
            cancelText={translate("buttons.cancel")}
            icon={<InfoCircleFilled />}
            onPopupClick={(e) => e.stopPropagation()}
            okButtonProps={{ danger: true, type: "default" }}
            onConfirm={() => record.id && deleteDocument(record.id)}
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        )}
      />
    </Table>
  );
};
