import { BaseRecord, useTranslate } from "@refinedev/core";
import { Col, Divider, Row } from "antd";
import { Address, Completed, Created, Name, Reference, Status } from ".";

type Props = {
  record?: BaseRecord;
  editable?: boolean;
};

export const General = ({ record, editable }: Props) => {
  const translate = useTranslate();
  const span = 8;

  return (
    <>
      <Divider>{translate("pages.invoice.fields.general")}</Divider>
      <Row style={{ marginTop: "-10px" }}>
        <Col span={span}>
          <Reference reference={record?.reference} editable={editable} />
        </Col>
        <Col span={span}>
          <Name name={record?.patient?.name} editable={editable} />
        </Col>
        <Col span={span}>
          <Status status={record?.status} editable={editable} />
        </Col>
      </Row>
      <Row>
        <Col span={span}>
          <Created createdAt={record?.createdAt} />
        </Col>
        <Col span={span}>
          <Address address={record?.address} editable={editable} />
        </Col>
        <Col span={span}>
          <Completed completedOn={record?.completedOn} editable={editable} />
        </Col>
      </Row>
    </>
  );
};
