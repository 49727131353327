import { DateField } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Table, Tag } from "antd";

type Props = {
  treatments?: any[];
};

export const Treatments = ({ treatments }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const colorStatus: { [key: string]: string } = {
    PERFECT: "green",
    GOOD: "yellow",
    UNKNOWN: "cyan",
    BAD: "orange",
    WORST: "red",
  };

  return (
    <Table
      dataSource={treatments}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: () => record.id && show("treatment", record.id),
        };
      }}
      rowKey="id"
    >
      <Table.Column
        dataIndex="reference"
        title={translate("pages.patient.fields.reference")}
        width="20%"
      />
      <Table.Column
        title={translate("pages.patient.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
      />
      <Table.Column
        title={translate("pages.patient.fields.completedOn")}
        dataIndex="completedOn"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
      />
      <Table.Column
        title={translate("selects.treatment.title")}
        dataIndex="status"
        render={(value: any) => (
          <Tag color={colorStatus[value ?? "UNKNOWN"]}>
            {translate(
              "selects.treatment." + String(value ?? "UNKNOWN").toLowerCase()
            )}
          </Tag>
        )}
        width="40%"
      />
    </Table>
  );
};
