import gql from "graphql-tag";

export const USER_CREATE_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const USER_SIGN_IN_MUTATION = gql`
  mutation SignInUser($input: SignInUserInput!) {
    signInUser(input: $input) {
      access {
        token
        expire
      }
      refresh {
        token
        expire
      }
      user {
        id
        email
        name
        role
      }
    }
  }
`;

export const USER_GET_QUERY = gql`
  query User {
    user {
      id
      createdAt
      updatedAt
      email
      name
      role
    }
  }
`;

export const USER_SIGN_OUT_MUTATION = gql`
  mutation SignOutUser($input: SignOutUserInput!) {
    signOutUser(input: $input)
  }
`;

export const USER_REFRESH_MUTATION = gql`
  mutation RefreshUser($input: RefreshUserInput!) {
    refreshUser(input: $input) {
      access {
        token
        expire
      }
      refresh {
        token
        expire
      }
      user {
        id
        email
        name
        role
      }
    }
  }
`;

export const USERS_LIST_QUERY = gql`
  query Users($paging: PagingInput, $filter: FilterInput) {
    users(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        updatedAt
        email
        role
      }
      totalCount
    }
  }
`;

export const USER_EDIT_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      createdAt
      updatedAt
      email
      name
      role
    }
  }
`;
