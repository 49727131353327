import { PrinterOutlined } from "@ant-design/icons";
import { BlobProvider } from "@react-pdf/renderer";
import { DateField, TextField } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Table, Tag } from "antd";
import { Invoice } from "../pdf";

type Props = {
  invoices?: any[];
};

export const Invoices = ({ invoices }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const colorStatus: { [key: string]: string } = {
    PAID: "green",
    OPEN: "red",
  };

  return (
    <Table
      dataSource={invoices}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: () => record.id && show("invoice", record.id),
        };
      }}
      rowKey="id"
    >
      <Table.Column
        dataIndex="reference"
        title={translate("pages.patient.fields.reference")}
        width="20%"
      />
      <Table.Column
        title={translate("pages.patient.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
      />
      <Table.Column
        title={translate("pages.patient.fields.completedOn")}
        dataIndex="completedOn"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
      />
      <Table.Column
        title={translate("pages.patient.fields.amount")}
        dataIndex="amount"
        render={(value: any) => (
          <TextField value={Number(value).toFixed(2) + " EUR"} />
        )}
        width="20%"
      />
      <Table.Column
        title={translate("selects.payment.title")}
        dataIndex="status"
        render={(value: any) => (
          <Tag color={colorStatus[value]}>
            {translate("selects.payment." + String(value).toLowerCase())}
          </Tag>
        )}
        width="20%"
      />
      <Table.Column
        title={translate("table.actions")}
        render={(_, record) => (
          <BlobProvider
            document={<Invoice translate={translate} record={record} />}
          >
            {({ url }) => (
              <Button
                type="default"
                size="large"
                icon={<PrinterOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  url && window.open(url, "_blank")?.focus();
                }}
              />
            )}
          </BlobProvider>
        )}
      />
    </Table>
  );
};
