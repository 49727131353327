import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import { CanAccess, useShow, useTranslate } from "@refinedev/core";
import { Row } from "antd";
import { PATIENT_DELETE_MUTATION, PATIENT_SHOW_QUERY } from "../../queries";
import { Unauthorized } from "../../components/common";
import {
  General,
  Contact,
  Insurance,
  Additional,
} from "../../components/patient";

export const PatientShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: PATIENT_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.patient.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: PATIENT_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Row gutter={20}>
          <General record={record} />
          <Contact
            email={record?.email}
            telPrivate={record?.telPrivate}
            telPublic={record?.telPublic}
          />
          <Insurance
            insuranceName={record?.insuranceName}
            insuranceNumber={record?.insuranceNumber}
          />
        </Row>
        <Additional
          name={record?.name}
          invoices={record?.invoices}
          documents={record?.documents}
          treatments={record?.treatments}
          receipts={record?.receipts}
        />
      </Show>
    </CanAccess>
  );
};
