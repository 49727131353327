import { Route } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import {
  TreatmentCreate,
  TreatmentEdit,
  TreatmentShow,
  TreatmentsList,
} from ".";

export const TreatmentRoute = () => (
  <Route path="/treatment">
    <Route index element={<TreatmentsList />} />
    <Route path="create" element={<TreatmentCreate />} />
    <Route path="edit/:id" element={<TreatmentEdit />} />
    <Route path="show/:id" element={<TreatmentShow />} />
  </Route>
);

export const TreatmentProps = {
  name: "treatment",
  list: "/treatment",
  create: "/treatment/create",
  edit: "/treatment/edit/:id",
  show: "/treatment/show/:id",
  meta: {
    canDelete: true,
    icon: <FileTextOutlined />,
  },
};
