import { DateField, List, TextField, useTable } from "@refinedev/antd";
import { CanAccess, CrudFilters, useNavigation, useTranslate } from "@refinedev/core";
import { Table } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { TREATMENTS_LIST_QUERY } from "../../queries";
import { Unauthorized } from "../../components/common";

export const TreatmentsList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show } = useNavigation();
  const { tableProps } = useTable({
    resource: "treatments",
    syncWithLocation: true,
    meta: {
      gqlQuery: TREATMENTS_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.treatment.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record, _) => {
            return {
              onClick: () => record.id && show("treatment", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="reference"
            title={translate("pages.treatment.fields.reference")}
            width="10%"
          />
          <Table.Column
            dataIndex="createdAt"
            title={translate("pages.treatment.fields.createdAt")}
            render={(value: any) => <DateField value={value} locales="de-DE" />}
            width="10%"
          />
          <Table.Column
            dataIndex="patient"
            title={translate("pages.treatment.fields.name")}
            render={(value: any) => <TextField value={value.name} />}
          />
          <Table.Column
            dataIndex="status"
            title={translate("selects.treatment.title")}
            render={(value: any) =>
              value &&
              translate("selects.treatment." + String(value).toLowerCase())
            }
            width="20%"
          />
          <Table.Column
            dataIndex="updatedAt"
            title={translate("pages.treatment.fields.updatedAt")}
            render={(value: any) => <DateField value={value} locales="de-DE" />}
            width="15%"
          />
        </Table>
      </List>
    </CanAccess>
  );
};
