import gql from "graphql-tag";

export const FILES_LIST_BY_ID_QUERY = gql`
  query Files($paging: PagingInput!, $filter: FilterInput) {
    files(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        name
        size
      }
      totalCount
    }
  }
`;

export const FILE_GET_LINK_QUERY = gql`
  query File($id: ID!) {
    file(id: $id)
  }
`;

export const FILE_DELETE_MUTATION = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;
