import type { AuthProvider } from "@refinedev/core";
import { gqlClient } from "./data-provider";
import {
  USER_CREATE_MUTATION,
  USER_SIGN_IN_MUTATION,
  USER_SIGN_OUT_MUTATION,
} from "../queries";
import { identity } from ".";

export const TOKEN_KEY = "refine-auth";

const sendRequest = async <T>(mutation: any, options?: any) => {
  return gqlClient.request<T>(mutation, options);
};

export const authProvider: AuthProvider = {
  register: async ({ email, password }) => {
    try {
      if (!email || !password) {
        return {
          success: false,
          error: {
            name: "Register error",
            message: "Invalid username or password",
          },
        };
      }

      let response = await sendRequest<{
        createUser: {
          id: number;
          createdAt: Date;
          updatedAt: Date;
          email: string;
          name: string;
        };
      }>(USER_CREATE_MUTATION, {
        input: {
          email,
          password,
        },
      });

      if (!response.createUser.id || response.createUser.id <= 0) {
        return {
          success: false,
          error: {
            name: "Register error",
            message: "Invalid username or password",
          },
        };
      }

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          name: "Register error",
          message: e.message,
        },
      };
    }
  },
  login: async ({ email, password }) => {
    try {
      if (!email || !password) {
        return {
          success: false,
          error: {
            name: "Login error",
            message: "Invalid username or password",
          },
        };
      }

      let response = await sendRequest<{
        signInUser?: {
          access: {
            token: string;
            expire: Date;
          };
          refresh: {
            token: string;
            expire: Date;
          };
          user: {
            id: number;
            email: string;
            name: string;
            role: string;
          };
        };
      }>(USER_SIGN_IN_MUTATION, {
        input: {
          email,
          password,
        },
      });

      if (
        !response.signInUser ||
        !response.signInUser.access ||
        !response.signInUser.refresh ||
        !response.signInUser.user
      ) {
        return {
          success: false,
          error: {
            name: "Login error",
            message: "No information received",
          },
        };
      }

      identity.setData(
        response.signInUser.user,
        response.signInUser.refresh,
        response.signInUser.access
      );

      return {
        success: true,
        redirectTo: "/",
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          name: "Login error",
          message: e.message,
        },
      };
    }
  },
  logout: async () => {
    try {
      if (!identity.hasRefreshToken() && !identity.isRefreshTokenValid()) {
        identity.clearData();
        return {
          success: true,
          redirectTo: "/login",
        };
      }

      let refresh = identity.refreshToken();
      let user = identity.identity();

      await sendRequest<boolean>(USER_SIGN_OUT_MUTATION, {
        input: {
          email: user.email,
          refresh: refresh.token,
        },
      });
      identity.clearData();

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (err: any) {
      identity.clearData();
      return {
        success: true,
        redirectTo: "/login",
      };
    }
  },
  check: async () => {
    try {
      if (identity.hasAccessToken() && identity.isAccessTokenValid()) {
        if (!identity.hasAuthHeaderSet()) {
          identity.setAuthHeader();
        }
        return {
          authenticated: true,
        };
      }

      if (
        identity.hasRefreshToken() &&
        identity.isRefreshTokenValid() &&
        (await identity.refresh())
      ) {
        if (identity.hasAccessToken() && identity.isAccessTokenValid()) {
          return {
            authenticated: true,
          };
        }
      }

      return {
        authenticated: false,
        redirectTo: "/login",
      };
    } catch (e: any) {
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }
  },
  getPermissions: async () => identity.permission(),
  getIdentity: async () => identity.identity(),
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
