import gql from "graphql-tag";

export const INVOICES_LIST_QUERY = gql`
  query Invoices($paging: PagingInput!, $filter: FilterInput) {
    invoices(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        reference
        status
        amount
        completedOn
        patient {
          name
        }
      }
      totalCount
    }
  }
`;

export const INVOICE_CREATE_MUTATION = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      createdAt
      updatedAt
      status
      completedOn
      tax
      discountValue
      discountType
      amount
      patient {
        id
        name
      }
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      address {
        id
        name
        street
        postcode
        city
        country
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        amountOne
        amountSum
        feeType
      }
    }
  }
`;

export const INVOICE_EDIT_MUTATION = gql`
  mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      tax
      discountValue
      discountType
      amount
      patient {
        id
        name
      }
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      address {
        id
        name
        street
        postcode
        city
        country
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        amountOne
        amountSum
        feeType
      }
    }
  }
`;

export const INVOICE_DELETE_MUTATION = gql`
  mutation DeleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input) {
      id
    }
  }
`;

export const INVOICE_SHOW_QUERY = gql`
  query Invoice($id: ID!) {
    invoice(id: $id) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      tax
      discountValue
      discountType
      amount
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      patient {
        id
        name
      }
      address {
        id
        name
        street
        postcode
        city
        country
      }
      positions {
        id
        service {
          id
          name
          reference
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        amountOne
        amountSum
        feeType
      }
      settings {
        companyName
        companyUrl
        companyStreet
        companyPostcode
        companyCity
        companyCourt
        companyTax
        companyRegister
        companyTel
        companyFax
        companyEmail
        companyIBAN
        companyBIC
        companyBank
        companyOwner
      }
    }
  }
`;
