import { useTranslate } from "@refinedev/core";
import Card from "antd/lib/card";
import Divider from "antd/lib/divider";
import List from "antd/lib/list";
import { CSSProperties, useRef, useState } from "react";
import { Button, Form } from "antd";
import { Watch } from "antx";
import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { API_URL, gqlClient } from "../../provider";
import { EmptyData, HeaderText } from "../common";

type Props = {
  editable?: boolean;
  files?: any[];
  style?: CSSProperties;
};

export const Files = ({ editable, style, files }: Props) => {
  const translate = useTranslate();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [upload, setUpload] = useState(false);

  const humanFileSize = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  const handleUpload = async (file: File) => {
    const data = new FormData();
    data.append("file", file, file.name);
    let response = await fetch(API_URL + "/files", {
      headers: gqlClient.requestConfig.headers as any,
      method: "POST",
      body: data,
    });

    if (response.status >= 200 && response.status <= 299) {
      const res = await response.json();
      return {
        name: file.name,
        url: res.url,
        key: res.key,
        size: file.size,
      };
    }
  };

  const renderShowFile = () => {
    if ((files?.length ?? 0) > 0) {
      return (
        <List
          locale={{ emptyText: <div /> }}
          grid={{
            column: 3,
            gutter: 16,
          }}
          dataSource={files}
          style={{ margin: "0px" }}
          renderItem={(item: any) => (
            <Card
              size="small"
              style={{ margin: "5px" }}
              actions={[
                <EyeOutlined key="view" onClick={() => handleView(item.url)} />,
              ]}
              bordered={false}
              cover={
                <img
                  height={80}
                  src={
                    String(item.name).endsWith("pdf")
                      ? "/images/icons/pdf.svg"
                      : item.url
                  }
                  style={{ paddingBottom: "10px", objectFit: "contain" }}
                />
              }
            >
              <Meta title={item.name} description={humanFileSize(item.size)} />
            </Card>
          )}
        />
      );
    } else {
      return <EmptyData text={translate("empty_data")} height={50} />;
    }
  };

  const handleView = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  const renderEditFile = () => (
    <Form.List name="files">
      {(fields, { add, remove }) => (
        <>
          <List
            locale={{ emptyText: <div /> }}
            grid={{
              column: 3,
              gutter: 16,
            }}
            dataSource={fields}
            style={{
              margin: "0px",
              marginTop: fields.length > 0 ? "0px" : "-30px",
            }}
            renderItem={(item) => (
              <Watch
                list={[
                  ["files", item.name, "name"],
                  ["files", item.name, "url"],
                  ["files", item.name, "size"],
                ]}
              >
                {([name, url, size]) => (
                  <Card
                    size="small"
                    style={{ margin: "5px" }}
                    actions={[
                      <EyeOutlined
                        key="view"
                        onClick={() => handleView(url)}
                      />,
                      <DeleteOutlined
                        key="delete"
                        onClick={() => remove(item.name)}
                      />,
                    ]}
                    bordered={false}
                    cover={
                      <img
                        height={80}
                        src={
                          String(name).endsWith("pdf")
                            ? "/images/icons/pdf.svg"
                            : url
                        }
                        style={{ paddingBottom: "10px", objectFit: "contain" }}
                      />
                    }
                  >
                    <Meta title={name} description={humanFileSize(size)} />
                  </Card>
                )}
              </Watch>
            )}
          />
          <Form.Item style={{ margin: "0px", paddingTop: "10px" }}>
            <input
              type="file"
              ref={inputFile}
              onChange={async (event) => {
                if (event?.currentTarget?.files) {
                  setUpload(true);
                  let file = await handleUpload(event?.currentTarget?.files[0]);
                  if (file) {
                    add(file);
                  }
                  setUpload(false);
                }
              }}
              style={{ display: "none" }}
            />
            <Button
              type="dashed"
              onClick={() => inputFile?.current?.click()}
              block
              icon={<UploadOutlined />}
              disabled={upload}
            >
              {upload ? translate("buttons.upload") : translate("buttons.add")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );

  return (
    <>
      <Divider>{translate("pages.treatment.fields.additional")}</Divider>
      <HeaderText
        title={translate("pages.treatment.fields.files")}
        style={style}
      >
        {editable ? renderEditFile() : renderShowFile()}
      </HeaderText>
    </>
  );
};
