import gql from "graphql-tag";

export const TREATMENTS_LIST_QUERY = gql`
  query Treatments($paging: PagingInput!, $filter: FilterInput) {
    treatments(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        status
        reference
        patient {
          name
        }
      }
      totalCount
    }
  }
`;

export const TREATMENT_SHOW_QUERY = gql`
  query Treatment($id: ID!) {
    treatment(id: $id) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      patient {
        id
        name
      }
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        description
      }
      receipts {
        id
        reference
        prescribed
        ingestion
      }
      files {
        id
        name
        key
        size
        url
      }
      settings {
        companyName
        companyUrl
        companyStreet
        companyPostcode
        companyCity
        companyCourt
        companyTax
        companyRegister
        companyTel
        companyFax
        companyEmail
        companyIBAN
        companyBIC
        companyBank
        companyOwner
      }
    }
  }
`;

export const TREATMENT_CREATE_MUTATION = gql`
  mutation CreateTreatment($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      patient {
        id
        name
      }
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        description
      }
      receipts {
        prescribed
        ingestion
      }
      files {
        name
        key
        size
      }
    }
  }
`;

export const TREATMENT_EDIT_MUTATION = gql`
  mutation UpdateTreatment($input: UpdateTreatmentInput!) {
    updateTreatment(input: $input) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      diagnoses {
        id
        createdAt
        updatedAt
        description
        code
        icdEntry {
          id
          code
          description
        }
      }
      patient {
        id
        name
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        description
      }
      receipts {
        id
        reference
        prescribed
        ingestion
      }
      files {
        id
        name
        key
        size
        url
      }
    }
  }
`;

export const TREATMENT_DELETE_MUTATION = gql`
  mutation DeleteTreatment($input: DeleteTreatmentInput!) {
    deleteTreatment(input: $input) {
      id
    }
  }
`;
