import { BlobProvider } from "@react-pdf/renderer";
import { DateField, TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Receipt } from "../pdf";

type Props = {
  name?: string;
  receipts?: any[];
};

export const Receipts = ({ receipts, name }: Props) => {
  const translate = useTranslate();

  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  return (
    <Table dataSource={receipts} rowKey="id">
      <Table.Column
        title={translate("pages.patient.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
      />
      <Table.Column
        title={translate("pages.patient.fields.prescribed")}
        dataIndex="prescribed"
        render={(value: any) => (
          <TextField value={truncateString(String(value ?? "n.A."), 150)} />
        )}
      />
      <Table.Column
        title={translate("pages.patient.fields.ingestion")}
        dataIndex="ingestion"
        render={(value: any) => (
          <TextField value={truncateString(String(value ?? "n.A."), 150)} />
        )}
      />
      <Table.Column
        title={translate("table.actions")}
        render={(_, record: any) => (
          <BlobProvider
            document={
              <Receipt record={{ name, ...record }} translate={translate} />
            }
          >
            {({ url }) => (
              <Button
                type="default"
                size="large"
                icon={<PrinterOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  url && window.open(url, "_blank")?.focus();
                }}
              />
            )}
          </BlobProvider>
        )}
      />
    </Table>
  );
};
