import { gqlClient } from ".";
import { USER_REFRESH_MUTATION } from "../queries";

const ACCESS_KEY = "access";
const REFRESH_KEY = "refresh";
const USER_KEY = "user";

class IdentityProvider {
  isAuthHeaderSet = false;

  refreshToken() {
    return this.getData().refresh;
  }

  accessToken() {
    return this.getData().access;
  }

  hasAccessToken() {
    return this.getData().access !== undefined;
  }

  hasRefreshToken() {
    return this.getData().refresh !== undefined;
  }

  hasAuthHeaderSet() {
    return this.isAuthHeaderSet;
  }

  isAccessTokenValid() {
    return (
      new Date(this.getData()?.access?.expire).getTime() > new Date().getTime()
    );
  }

  isRefreshTokenValid() {
    return (
      new Date(this.getData()?.refresh?.expire).getTime() > new Date().getTime()
    );
  }

  permission() {
    return this.getData().user?.email;
  }

  identity() {
    return this.getData().user;
  }

  setAuthHeader(token?: string) {
    gqlClient.setHeader("auth", token ? token : this.getData().access.token);
    this.isAuthHeaderSet = true;
  }

  clearData() {
    localStorage.removeItem(REFRESH_KEY);

    sessionStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(ACCESS_KEY);

    gqlClient.requestConfig.headers = undefined;
    this.isAuthHeaderSet = false;
  }

  getData() {
    let userString = sessionStorage.getItem(USER_KEY);
    let accessString = sessionStorage.getItem(ACCESS_KEY);
    let refreshString = localStorage.getItem(REFRESH_KEY);

    return {
      user: userString ? JSON.parse(userString) : undefined,
      refresh: refreshString ? JSON.parse(refreshString) : undefined,
      access: accessString ? JSON.parse(accessString) : undefined,
    };
  }

  setData(
    user: {
      id: number;
      email: string;
      name: string;
      role: string;
    },
    refresh: { token: string; expire: Date },
    access: { token: string; expire: Date }
  ) {
    localStorage.setItem(REFRESH_KEY, JSON.stringify(refresh));

    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    sessionStorage.setItem(ACCESS_KEY, JSON.stringify(access));

    this.setAuthHeader(access.token);
    this.scheduleRefresh(new Date(access.expire));
  }

  async refresh() {
    let response = await gqlClient.request<{
      refreshUser?: {
        access: {
          token: string;
          expire: Date;
        };
        refresh: {
          token: string;
          expire: Date;
        };
        user: {
          id: number;
          email: string;
          name: string;
          role: string;
        };
      };
    }>(USER_REFRESH_MUTATION, {
      input: {
        email: this.identity().email,
        refresh: this.refreshToken().token,
      },
    });

    if (
      !response.refreshUser ||
      !response.refreshUser.access ||
      !response.refreshUser.refresh
    ) {
      return false;
    } else {
      identity.setData(
        response.refreshUser.user,
        response.refreshUser.refresh,
        response.refreshUser.access
      );
      return true;
    }
  }

  scheduleRefresh(expire: Date) {
    setTimeout(async () => {
      await this.refresh();
    }, new Date(expire).getTime() - new Date().getTime());
  }
}

export const identity = new IdentityProvider();
