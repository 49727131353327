import { BaseRecord, useTranslate } from "@refinedev/core";
import { Col, Divider, Row } from "antd";
import { Completed, Created, Name, Reference, Status, Updated } from ".";

type Props = {
  record?: BaseRecord;
  editable?: boolean;
};

export const General = ({ record, editable }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider>{translate("pages.treatment.fields.general")}</Divider>
      <Row style={{ marginTop: "-10px" }}>
        <Col span={6}>
          <Created createdAt={record?.createdAt} />
        </Col>
        <Col span={8}>
          <Reference reference={record?.reference} editable={editable} />
        </Col>
        <Col span={10}>
          <Status status={record?.status} editable={editable} />
        </Col>
      </Row>
      <Row style={{ marginTop: "-10px" }}>
        <Col span={6}>
          <Updated updatedAt={record?.updatedAt} />
        </Col>
        <Col span={10}>
          <Name name={record?.patient?.name} editable={editable} />
        </Col>
        <Col span={8}>
          <Completed completedOn={record?.completedOn} editable={editable} />
        </Col>
      </Row>
    </>
  );
};
