import { Create, useForm } from "@refinedev/antd";
import { Form, Row } from "antd";
import { PATIENT_CREATE_MUTATION } from "../../queries";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Contact, General, Insurance } from "../../components/patient";

export const PatientCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    meta: {
      gqlMutation: PATIENT_CREATE_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.patient.titles.create")}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            address: [{ type: "HOME", default: true }],
          }}
        >
          <Row gutter={20}>
            <General editable />
            <Contact editable />
            <Insurance editable />
          </Row>
        </Form>
      </Create>
    </CanAccess>
  );
};
