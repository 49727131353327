import { Route } from "react-router-dom";
import { UsersList } from ".";
import { TeamOutlined } from "@ant-design/icons";

export const UserRoute = () => (
  <Route path="user" element={<UsersList />} />
);

export const UserProps = {
  name: "user",
  meta: {
    parent: "setting",
    icon: <TeamOutlined />,
  },
  list: "/setting/user",
};
