import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import { CanAccess, useShow, useTranslate } from "@refinedev/core";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { TREATMENT_DELETE_MUTATION, TREATMENT_SHOW_QUERY } from "../../queries";
import {
  General,
  Receipts,
  Diagnose,
  Treatment,
  Results,
  Files,
  Positions,
} from "../../components/treatment";
import { Unauthorized } from "../../components/common";

export const TreatmentShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: TREATMENT_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        isLoading={isLoading}
        breadcrumb={false}
        title={translate("pages.treatment.titles.show")}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: TREATMENT_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Row gutter={20}>
          <Col md={14}>
            <General record={record} />
            <Diagnose
              diagnoses={record?.diagnoses}
              style={{ minHeight: "150px" }}
            />
            <Treatment treatment={record?.treatment} />
            <Results results={record?.results} />
          </Col>
          <Col md={10}>
            <Files files={record?.files} />
            <Receipts
              receipts={record?.receipts}
              name={record?.patient?.name}
              settings={record?.settings}
            />
          </Col>
        </Row>
        <Positions positions={record?.positions} />
      </Show>
    </CanAccess>
  );
};
