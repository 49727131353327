import { Edit, useForm } from "@refinedev/antd";
import { Col, Form, Row } from "antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { INVOICE_DELETE_MUTATION, INVOICE_EDIT_MUTATION } from "../../queries";
import { Unauthorized } from "../../components/common";
import { Amount, Diagnose, General, Positions } from "../../components/invoice";

export const InvoiceEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlMutation: INVOICE_EDIT_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Edit
        saveButtonProps={saveButtonProps}
        deleteButtonProps={{ meta: { gqlMutation: INVOICE_DELETE_MUTATION } }}
        isLoading={formLoading}
        breadcrumb={false}
        title={translate("pages.invoice.titles.edit")}
      >
        <Form {...formProps}>
          <Row gutter={20}>
            <Col md={16}>
              <General editable />
              <Diagnose editable />
            </Col>
            <Col md={8}>
              <Amount editable />
            </Col>
          </Row>
          <Positions editable />
        </Form>
      </Edit>
    </CanAccess>
  );
};
