import {
  DateField,
  EditButton,
  SaveButton,
  TextField,
  useEditableTable,
} from "@refinedev/antd";
import { Button, Form, List, Space, Table } from "antd";
import { USERS_LIST_QUERY, USER_EDIT_MUTATION } from "../../../queries";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Input, Select } from "antx";
import { Unauthorized } from "../../../components/common";

export const UsersList = () => {
  const translate = useTranslate();

  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable({
    resource: "users",
    meta: {
      gqlMutation: USER_EDIT_MUTATION,
      gqlQuery: USERS_LIST_QUERY,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List>
        <Form {...formProps}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="id"
              title={translate("pages.user.fields.id")}
              width="5%"
            />
            <Table.Column
              dataIndex="createdAt"
              title={translate("pages.user.fields.created_at")}
              render={(value) => <DateField value={value} locales="de-DE" />}
              width="10%"
            />
            <Table.Column
              dataIndex="updatedAt"
              title={translate("pages.user.fields.created_at")}
              render={(value) => <DateField value={value} locales="de-DE" />}
              width="10%"
            />
            <Table.Column
              dataIndex="email"
              title={translate("pages.user.fields.email")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Input
                      name="email"
                      initialValue={value}
                      style={{ margin: 0 }}
                    />
                  );
                }
                return <TextField value={value} />;
              }}
              width="30%"
            />
            <Table.Column
              dataIndex="name"
              title={translate("pages.user.fields.name")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Input
                      name="name"
                      initialValue={value}
                      style={{ margin: 0 }}
                    />
                  );
                }
                return <TextField value={value} />;
              }}
              width="20%"
            />
            <Table.Column
              dataIndex="role"
              title={translate("selects.role.title")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Select
                      name="role"
                      initialValue={value}
                      style={{ margin: 0 }}
                      options={[
                        {
                          value: "GUEST",
                          label: translate("selects.role.guest"),
                        },
                        {
                          value: "USER",
                          label: translate("selects.role.user"),
                        },
                        {
                          value: "ADMIN",
                          label: translate("selects.role.admin"),
                        },
                      ]}
                    />
                  );
                }
                return (
                  <TextField
                    value={translate(
                      "selects.role." + String(value).toLowerCase()
                    )}
                  />
                );
              }}
              width="20%"
            />
            <Table.Column
              title={translate("table.actions")}
              dataIndex="actions"
              width="5%"
              render={(_, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton {...saveButtonProps} hideText />
                      <Button {...cancelButtonProps}>
                        {translate("buttons.cancel")}
                      </Button>
                    </Space>
                  );
                }
                return <EditButton {...editButtonProps(record.id)} hideText />;
              }}
            />
          </Table>
        </Form>
      </List>
    </CanAccess>
  );
};
