import { Route } from "react-router-dom";
import { EuroCircleOutlined } from "@ant-design/icons";
import { InvoiceCreate, InvoiceEdit, InvoiceShow, InvoicesList } from ".";

export const InvoiceRoute = () => (
  <Route path="/invoice">
    <Route index element={<InvoicesList />} />
    <Route path="create" element={<InvoiceCreate />} />
    <Route path="edit/:id" element={<InvoiceEdit />} />
    <Route path="show/:id" element={<InvoiceShow />} />
  </Route>
);

export const InvoiceProps = {
  name: "invoice",
  list: "/invoice",
  create: "/invoice/create",
  edit: "/invoice/edit/:id",
  show: "/invoice/show/:id",
  meta: {
    canDelete: true,
    icon: <EuroCircleOutlined />,
  },
};
