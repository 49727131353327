import { useTranslate } from "@refinedev/core";
import { Button, Collapse, Divider, Flex, Form, Typography, theme } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { TextArea, Watch } from "antx";
import { BlobProvider } from "@react-pdf/renderer";
import { EmptyData, HeaderText } from "../common";
import { Receipt } from "../pdf";

type Props = {
  editable?: boolean;
  receipts?: any[];
  settings?: any[];
  name?: string;
};

export const Receipts = ({ editable, receipts, name, settings }: Props) => {
  const translate = useTranslate();
  const { token } = theme.useToken();

  const renderShowReceipt = () => {
    if ((receipts?.length ?? 0) > 0) {
      return (
        <Collapse
          accordion
          bordered={false}
          style={{ background: token.colorBgContainer }}
          items={receipts?.map((receipt) => ({
            key: receipt.id,
            label: (
              <Typography.Text style={{ verticalAlign: "middle" }}>
                {receipt.reference}
              </Typography.Text>
            ),
            style: {
              background: token.colorFillAlter,
              borderRadius: token.borderRadiusLG,
              marginBottom: "20px",
              border: "none",
            },
            extra: (
              <BlobProvider
                document={
                  <Receipt
                    record={{ name, settings, ...receipt }}
                    translate={translate}
                  />
                }
              >
                {({ url }) => (
                  <Button
                    type="default"
                    size="small"
                    icon={<PrinterOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      url && window.open(url, "_blank")?.focus();
                    }}
                  >
                    {translate("buttons.print")}
                  </Button>
                )}
              </BlobProvider>
            ),
            showArrow: false,
            children: (
              <Flex vertical gap={15}>
                <Divider style={{ fontSize: "small" }}>
                  {translate("pages.treatment.titles.prescribed")}
                </Divider>
                <Typography.Text
                  style={{ marginTop: "-20px", marginBottom: "10px" }}
                >
                  {receipt.prescribed}
                </Typography.Text>
                <Divider style={{ fontSize: "small", marginTop: "0px" }}>
                  {translate("pages.treatment.titles.ingestion")}
                </Divider>
                <Typography.Text
                  style={{ marginTop: "-20px", marginBottom: "10px" }}
                >
                  {receipt.ingestion}
                </Typography.Text>
              </Flex>
            ),
          }))}
        />
      );
    } else {
      return <EmptyData text={translate("empty_data")} height={50} />;
    }
  };

  const renderEditReceipt = () => (
    <Form.List name="receipts">
      {(fields, { add, remove }) => (
        <>
          <Collapse
            accordion
            bordered={false}
            style={{ background: token.colorBgContainer }}
            items={fields.map(({ name, key }) => ({
              key,
              label: (
                <Watch name={["receipts", name, "reference"]}>
                  {(reference) => (
                    <Typography.Text
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {reference
                        ? reference
                        : translate("pages.treatment.titles.receipt") +
                          " " +
                          Number(key + 1)}
                    </Typography.Text>
                  )}
                </Watch>
              ),
              style: {
                background: token.colorFillAlter,
                borderRadius: token.borderRadiusLG,
                marginBottom: "20px",
                border: "none",
              },
              extra: (
                <Button
                  type="default"
                  size="small"
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    remove(name);
                  }}
                  style={{ borderColor: "red" }}
                />
              ),
              showArrow: false,
              children: (
                <Flex vertical gap={15}>
                  <TextArea
                    name={[name, "prescribed"]}
                    autoSize={{ minRows: 6 }}
                    style={{ margin: "0px" }}
                    placeholder={translate(
                      "pages.treatment.placeholders.prescribed"
                    )}
                  />
                  <TextArea
                    name={[name, "ingestion"]}
                    autoSize={{ minRows: 6 }}
                    style={{ margin: "0px" }}
                    placeholder={translate(
                      "pages.treatment.placeholders.ingestion"
                    )}
                  />
                </Flex>
              ),
            }))}
          />
          <Form.Item style={{ margin: "0px", paddingTop: "10px" }}>
            <Button
              type="dashed"
              onClick={() =>
                add({ ingestion: undefined, prescribed: undefined })
              }
              block
            >
              <PlusOutlined /> {translate("buttons.add")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );

  return (
    <HeaderText
      title={translate("pages.treatment.fields.receipts")}
      style={{ minHeight: "0px" }}
    >
      {editable ? renderEditReceipt() : renderShowReceipt()}
    </HeaderText>
  );
};
